export const SPEAKER_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment speakerGenericPageHeaderFragment on Speaker {
    id
    uid
    firstName
    lastName
    prefix
    suffix
    employerName
    jobTitle
    __typename
    photoFileResource {
      schemaCode
      path
    }
    _isRecommendedForMe
  }
`;
